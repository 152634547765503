

[type=radio]{
    display: none;
}

#slider{
    height: 35vw;
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
    margin-top: -35px;
}


#slider label{
    margin: auto;
    width: 60%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform  0.4s  ease;
    transition: transform  0.4s  ease;
    transition: transform  0.4s  ease, -webkit-transform .4s ease;
    
    
}


#s1:checked ~ .cards #slide3,
#s2:checked ~ .cards #slide1,
#s3:checked ~ .cards #slide2{
    -webkit-transform: translateX(-40%) scale(0.8);
    transform: translateX(-40%) scale(0.8);

    z-index: 0;
}
#s1:checked ~ .cards #slide2,
#s2:checked ~ .cards #slide3,
#s3:checked ~ .cards #slide1{
    -webkit-transform: translateX(40%) scale(0.8);
    transform: translateX(40%) scale(0.8);
    z-index: 0;
}
#s1:checked ~ .cards #slide1,
#s2:checked ~ .cards #slide2,
#s3:checked ~ .cards #slide3{
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
    opacity: 1;
    z-index: 1;
}


















/* slider 2  */
#slider2{
    height: 35vw;
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
    margin-top: -35px;
}


#slider2 label{
    margin: auto;
    width: 60%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform  0.4s  ease;
    transition: transform  0.4s  ease;
    transition: transform  0.4s  ease, -webkit-transform .4s ease;
    
    
}

#s4:checked ~ .cards2 #slide6,
#s5:checked ~ .cards2 #slide4,
#s6:checked ~ .cards2 #slide5{
    -webkit-transform: translateX(-40%) scale(0.8);
    transform: translateX(-40%) scale(0.8);

    z-index: 0;
}
#s4:checked ~ .cards2 #slide5,
#s5:checked ~ .cards2 #slide6,
#s6:checked ~ .cards2 #slide4{
    -webkit-transform: translateX(40%) scale(0.8);
    transform: translateX(40%) scale(0.8);
    z-index: 0;
}
#s4:checked ~ .cards2 #slide4,
#s5:checked ~ .cards2 #slide5,
#s6:checked ~ .cards2 #slide6{
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
    opacity: 1;
    z-index: 1;
}





#sliderResponsive{
    height: 100%;
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
    margin-top: -35px;
}


#sliderResponsive label{
    margin: auto;
    display: flex;
    align-content: flex-start;
    align-items: center;
    flex-grow: 1;
    width: 88%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform  0.4s  ease;
    transition: transform  0.4s  ease;
    transition: transform  0.4s  ease, -webkit-transform .4s ease;
    
    
}


@Media (max-width:454px){
    #sliderResponsive label{
        height: 393px;
    }
}