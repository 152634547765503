.h4,
h4 {
  font-size: calc(1rem + .3vw);
}

.h5,
h5 {
  font-size: calc(0.9rem + .3vw);
}

.d-flex {
  display: flex;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.status-name {
  color: #fff;
  background: #6844f9;
  border: none;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 0.95rem;
}

.rev-section {
  padding: 15px 0;
  border-bottom: 1px solid #c6c6c6;
}

.mb-10 {
  margin-bottom: 10px
}

.mb-20 {
  margin-bottom: 20px;
}

.text-center {
  text-align: center
}

.facility {
  background: #eae5e5;
  color: #000;
  margin: 5px 5px 5px 0;
  padding: 1px 7px;
  border: 1px solid #d6d1d1;
  border-radius: 5px;
}

.reservation-review {
  padding: 10px;
}

.head-title {
  text-align: center;
  margin: 15px;
}

.head-title img {
  width: auto;
}

.btn-submit {
  width: 100%;
  background: #6744F9;
  border: 1px solid #fff;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 50px;
  font-weight: 600;
  display: inline-block;
  text-align: center;
}

.page-loader {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 46%;
  display: flex;
  justify-content: center;
  left: 0;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.review .react-stars {
  pointer-events: none;
}

.profile {
  width: 30px;
  border-radius: 100%;
  margin-right: 10px;
  height: 30px;
  overflow: hidden;
}

.no-profile {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 10px;
  background: #7cd4f2
}

.review {
  align-items: start;
  font-size: 12px;
  border-top: 1px solid #c6c3c3;
  border-radius: 10px;
  padding: 5px 5px;
  min-width: 227px;
  margin-right: 10px;
}

.review-card {
  align-items: start;
  font-size: 12px;
  border-top: 1px solid #c6c3c3;
  border-radius: 10px;
  padding: 7px 10px;
}

.reviews-wrapper {
  display: flex;
  overflow: auto;
}

.review .time,
.review-card .time {
  color: #c6c3c3;
  font-size: 8px;
}

.slider-wrapper {
  max-height: 200px;
}

.avg-rating {
  margin-top: 4px;
}

.start-rating {
  font-size: 13px;
  color: #6744F9;
}

.total-rating {
  margin-left: 15px;
  margin-top: 4px;
}

.navigation-top {
  background: #0000001f;
  padding: 5px;
  position: relative;
  z-index: 999;
  max-width: 500px;
  margin: 0 auto;
}

.carousel-slider .carousel-status {
  top: 5px;
  right: 10px;
}

.select-language {
  /* left: 76.5vw; */
  margin-right: 0;
}

.select-language button {
  padding: 0px;
  padding-right: 5px;
  border: 0;
  align-self: flex-end;
}

.select-language button span {
  padding-right: 0px;
}

.map_container {
  position: relative;
  overflow: hidden;
}

.map_container:after,
.map_container:before,
#map:after,
#map:before {
  content: '';
  position: absolute;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 75%);
  z-index: 1;
}

.map_container:after {
  right: -5px;
  top: 0;
  bottom: 0;
  width: 5px;
}

.map_container:before {
  top: -5px;
  left: 0;
  right: 0;
  height: 5px;
}

#map:before {
  bottom: -5px;
  left: 0;
  right: 0;
  height: 5px;
}

#map:after {
  left: -5px;
  top: 0;
  bottom: 0;
  width: 5px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}