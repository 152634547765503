.left-full .sideBar {
    left: -280px;
    transition: 0.5s left linear;
}

.left-mid .sideBar {
    left: 0px;
    transition: 0.5s left linear;
}

.left-full .backDrop {
    display: none;
}

.slider-bottom-padding {
    padding-top: -15px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: -1px !important;
}

.slider-bottom-paddingApp {
    padding-top: -15px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: -1px !important;
}

.slider-bottom-howDoesTabo {
    /* padding-top: -15px !important;
    margin-left: -1px !important; */
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 15px;
}

.left-mid .backDrop {
    display: block;
}

.contactFormInput {
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    mix-blend-mode: normal;
}

.goalBoxShadow {
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
}

.react-tabs__tab--selected {
    border-color: #6844F9 !important;
    border-radius: 0 !important;
    background-color: #6844F9 !important;
    color: #fff !important;
}

.customFirstRounded {
    border-radius: 30px 0 0 30px !important;
}

.marginTop1 {
    margin-top: 15px;
}

.marginTop2 {
    margin-top: 15px;
}

.customLastRounded {
    border-radius: 0 30px 30px 0 !important;
}

.react-tabs-ul li {
    cursor: pointer;
}

.react-tabs ul li:last-child {
    border-radius: 0 30px 30px 0 !important;
}

a:hover {
    color: #6844F9;
}

button:hover {
    background-color: #6844F9;
}

button,
a {
    transition: 0.3s linear;
    outline: none;
    cursor: pointer;
}

.max-w-1330px {
    max-width: 1330px;
}

.app-slider .slick-list .slick-track {
    max-width: 1330px;
    transform: none !important;
}

.slick-slide img {
    margin: auto;
}

.tabo-slider-carousel-content div:nth-child(1) {
    max-width: 289px !important;
    width: 100% !important;
    margin: 0 auto;
}

.bannerLabelShadow {
    box-shadow: 100px 80px rgb(0 0 0 / 7%), 0px 41.7776px 33.4221px rgb(0 0 0 / 5%), 0px 22.3363px 17.869px rgb(0 0 0 / 4%), 0px 12.5216px 10.0172px rgb(0 0 0 / 4%), 0px 6.6501px 5.32008px rgb(0 0 0 / 3%), 0px 2.76726px 2.21381px rgb(0 0 0 / 2%)
}

.flag-dropdown {
    /* width: 50px; */
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
    border-radius: 12px;
    margin-left: -8px;
}

.flag-dropdown ul li:last-child {
    border-bottom: none;
}

.services-carousel div:nth-child(2) {
    /* height: 290px; */
}

.why-tabo-carousel div:nth-child(2) {
    max-width: 300px;
}

.dece-card-boxShadow {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    /* height: 220px; */
}

/* .services-carousel.why-tabo-new div:first-child {
    height: 350px !important;
} */

.services-carousel.why-tabo-new div:nth-child(2) {
    /* height: 230px !important; */
}

.services-carousel.why-tabo-new button {
    margin-top: 0 !important;
}

.services-carousel.why-tabo-new div.icon-image {
    height: auto !important;
}

.services-carousel.why-tabo div:nth-child(2) {
    height: 240px !important;
}

.services-carousel.why-tabo button {
    margin-top: 0 !important;
}

/* .services-carousel.why-tabo-new.service-top div:nth-child(1) {
    height: 350px !important;
} */

.services-carousel.why-tabo-new.service-top div:nth-child(2) {
    /* min-height: 415px !important; */
}

.newBoxShadow {
    box-shadow: 0 4px 4px lightgrey;
    /* margin: 0 4px; */
    max-width: 100%;
    /* min-height: 380px; */
}

.slider-padding {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: -1px !important;
}

.slider-paddingApp {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-bottom: 25px;
}

.slider-paddingApp2 {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-bottom: 45px;
}

.slider-paddingAppReinvented {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-bottom: 45px;
}

.slider-paddingCustomers {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-bottom: 25px;
    margin-top: 10px;
}

.slider-paddingHowDoesTabo {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-bottom: 25px;
    margin-top: 10px;
}

.slider-paddingConatiner {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.slider-paddingHowDoesTaboContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-bottom: 25px;
}

.slider-paddingHowDoesTaboContainer2 {
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-bottom: 25px;
}

.slider-paddingMobile {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
}

.sliderPaddingBottom {
    margin-top: 25px;
    padding-bottom: 25px;
}

.services-carousel.why-tabo.prin-tabo-container div:nth-child(2) {
    height: 120px !important;
}

.shadowHowDoesTabo {
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275);
}

.dropShadow {
    mix-blend-mode: normal;
    border: 2px solid #EBEAED;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
}

.how-does-tabo-carousel {
    /* height: 290px; */
}

.how-does-tabo-carousel div:nth-child(2) {
    /* height: 270px; */
}

.how-does-tabo-carousel .h-how-tabo-img {
    height: 50px !important;
}

.how-does-tabo-carousel .slider-paddingApp2 {
    padding-bottom: 0px;
}

.pricing-desktop-hidden {
    display: flex !important;
}

.react-multi-carousel-list.carousel-container ul li {
    /* margin-left: 4px; */
}

.react-multi-carousel-list.carousel-container ul li div.border2 {
    height: 868px;
}

.react-multi-carousel-list.carousel-container ul li.react-multi-carousel-item.react-multi-carousel-item--active {
    padding: 0 22px;
}

.react-multi-carousel-list.carousel-container>ul.react-multi-carousel-track {
    padding-bottom: 25px;
}

.react-multi-carousel-dot--active button {
    background-color: #6844F9 !important;
    border: none !important;
}

.react-multi-carousel-dot button {
    background-color: #EBEAED;
    border: none !important;
}

.whyTabo .react-multi-carousel-list.carousel-container>ul.react-multi-carousel-track li {
    padding: 0 10px;
    opacity: 0;
}

.whyTabo .react-multi-carousel-list.carousel-container>ul.react-multi-carousel-track li.react-multi-carousel-item--active {
    opacity: 1;
}

.custom-carousel-item-class {
    height: 100%;
}

.pl-15-px {
    padding-left: 15px;
}

.read-icon:hover {
    src: url("../assets/images/youtube.png");
    background-color: red;
}

.modal-header-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.Select.text-left.mb-4.has-value.is-focused.is-searchable.Select--single .Select-value {
    display: none;
}

.Select.text-left.mb-4.has-value.is-focused.is-pseudo-focused.is-searchable.Select--single .Select-value {
    display: block;
}

@media (max-width: 992px) {
    .flag-dropdown {
        /* width: 50px; */
        border-radius: 5px;
    }

    .flag-dropdown ul li span {
        font-size: 8px;
    }
}

@media (min-width: 768px) {
    .app-slider-item {
        width: 700px;
        height: 350px;
    }

    .app-slider button {
        margin-top: 90px;
    }
}

@media (min-width: 992px) {
    .app-slider-item {
        width: 900px;
        height: 400px;
    }
}

@media (min-width: 1024px) {
    .main-banner {
        min-height: 450px;
    }
}

@media (min-width: 1200px) {
    .app-slider-item {
        width: 1210px;
        height: 500px;
    }
}

@media (max-width: 768px) {
    .app-slider-item {
        width: 576px;
        height: 250px;
    }

    .app-slider button {
        margin-top: 20px;
    }

    .app-slider-item2 {
        width: 97%;
        height: 100%;
        display: flex;
        justify-content: center;
        max-height: 100%;
    }



}

@media (max-width:768px) {
    .app-slider-item4 {
        width: 100%;
        height: 100%;
        max-height: 100%;
        display: flex;
        justify-content: center;

    }
}

@media (max-width: 640px) {
    .app-slider-item {
        width: 450px;
        height: 100%;
        max-height: 100%;
    }

    .app-slider-item2 {
        width: 97%;
        height: 100%;
        display: flex;
        justify-content: center;
        max-height: 100%;
    }

    .app-slider-item5 {
        width: 600px;
        height: 100%;
        display: flex;
        justify-content: center;
        max-height: 100%;
    }

    .heightConfigurePrices {
        height: 90vw;
    }

    .whyTaboHeightConfigure {
        height: 60vw;
    }

    .howDoesTaboConfigure {
        height: 40vw;
    }

    .buttonsTopConfigurate1 {
        margin-top: 45px;
    }

    .buttonsTopConfigurate2 {
        margin-top: 23px;
    }

    .buttonsTopConfigurate3 {
        margin-top: 80px;
    }

    .buttonsTopConfigurate4 {
        margin-top: 75px;
    }

    .buttonsTopConfigurate1Beach {
        margin-top: 25px;

    }

    .buttonsTopConfigurate2Beach {
        margin-top: 23px;
    }

    .buttonsTopConfigurate3Beach {
        margin-top: 55px;
    }

    .buttonsTopConfigurate4Beach {
        margin-top: 55px;
    }

    .br_class {
        display: flex;
    }



    .services-carousel button {
        width: 7px !important;
        height: 7px !important;
        margin-top: 25px;
    }

    .marginTop1 {
        margin-top: 15px;
    }

    .marginTop2 {
        padding-top: 65px;
    }

    .paddingTop1 {
        margin-top: 78px;
    }

    .how-does-tabo-carousel button {
        margin-top: 0;
        width: 7px !important;
        height: 7px !important;
    }

    .customer-reviews-section button {
        margin-top: 20px !important;
        width: 7px !important;
        height: 7px !important;
    }

    .btn-dropShadow {
        -webkit-filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
        filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
    }

    .print-tabo-banner {
        height: 300px;
    }

    .pricing-desktop-hidden {
        display: none !important;
    }

    .marginTop2 {
        margin-top: -10px;
    }

    /* .react-multi-carousel-list.carousel-container ul li.react-multi-carousel-item--active {
        max-width: 80%;
        overflow: hidden;
    }
    .react-multi-carousel-list.carousel-container ul li.react-multi-carousel-item--active .dece-card-boxShadow {
        padding: 0 10px;
    } */
}

@media (max-width: 576px) {
    .print-tabo-banner {
        height: 280px;
    }

    .react-multi-carousel-list.carousel-container ul li.react-multi-carousel-item.react-multi-carousel-item--active {
        padding: 0 8px;
    }

    .heightConfigurePrices {
        height: 100vw;
    }

    .react-multi-carousel-list.carousel-container ul li.react-multi-carousel-item {
        margin-bottom: 10px;
    }

    .react-multi-carousel-dot button {
        width: 8px !important;
        height: 8px !important;
    }

    .newBoxShadow {
        box-shadow: 1px 10px 13px lightgrey;
        margin: 0;
        max-width: 100%;
        width: 100%;
        min-height: inherit;
        height: 100%;
        max-height: 100%;
    }

    .customFirstRounded {}

    .whyTaboHeightConfigure {
        height: 70vw;
    }
}

@media (max-width:501px) {
    .whyTaboHeightConfigure {
        height: 80vw;
    }

    .howDoesTaboConfigure {
        height: 45vw;
    }
}

@media (max-width:510px) {
    .heightConfigurePrices {
        height: 120vw;
    }
}

@media (max-width:440px) {
    .heightConfigurePrices {
        height: 130vw;
    }

    .whyTaboHeightConfigure {
        height: 90vw;
    }

    .howDoesTaboConfigure {
        height: 50vw;
    }
}

@media (max-width:403px) {
    .heightConfigurePrices {
        height: 150vw;
    }

    .whyTaboHeightConfigure {
        height: 90vw;
    }

    .howDoesTaboConfigure {
        height: 60vw;
    }
}

@media (max-width:381px) {
    .heightConfigurePrices {
        height: 160vw;
    }
}


@media (max-width: 480px) {
    .app-slider-item {
        width: 380px;
    }

    .flag-dropdown {
        width: auto;
        border-radius: 5px;
        max-width: 100px;
    }

    .print-tabo-banner {
        height: 220px;
    }
}

@media (max-width: 425px) {
    .app-slider-item {
        width: 340px;
    }

    .print-tabo-banner {
        height: 180px;
    }

    .app-slider-item4 {
        width: 348px;
    }
}

@media (max-width:386px) {
    .app-slider-item4 {
        width: 306px;
    }
}

@media (max-width: 376px) {
    .app-slider-item {
        width: 320px;
    }

    .app-slider-item4 {
        width: 286px;
    }

    .print-tabo-banner {
        height: 150px;
    }
}

@media (max-width: 350px) {
    .app-slider-item {
        width: 290px;
    }


    .print-tabo-banner {
        height: 120px;
    }
}

@media screen and (max-device-width: 767px) and (orientation:landscape) {
    .video-header {
        height: 9%;
    }

    .video-body {
        height: 90%;
    }
}

.__react_component_tooltip {
    border: 1px solid #0b1b353d !important;
    text-align: justify;
    padding: 10px !important;
    background: #fff !important;
    opacity: 1 !important;
}

.__react_component_tooltip::after {
    border-color: #0b1b353d !important;
}
.slick-slider .slick-track {
    display: flex;
}
.slick-slider .slick-slide {
    float: none;
    height: auto;
}
.slick-slider .slick-slide > div {
    height: 100%;
}
.slick-slider .slick-dots li {
    width: auto;
    height: auto;
    margin: 0;
}
.slick-slider .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #6844F9;
}
.slick-slider .slick-dots li button:before {
    font-size: 10px;
}
.slick-slider .slick-prev:before, .slick-slider .slick-next:before {
    font-size: 30px;
    color: #6844F9;
}
.price-select-dropdown  .Select-value {
    width: 90%;
}
.price-select-dropdown  .Select-menu-outer {
    top: auto;
    bottom: 100%;
    border-radius: 4px 4px 0 0;
}
.price-select-dropdown .Select.is-focused > .Select-control {
    border-radius: 0 0 4px 4px;
}
